import { filter, map } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { UserState } from '../../@types/user';

// ----------------------------------------------------------------------

const initialState: UserState = {
  isLoading: false,
  success: false,
  error: false,
  user: null,
  users: [],
  staff: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // RESET
    resetFlags(state) {
      state.isLoading = false;
      state.success = false;
      state.error = false;
      state.user = null;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.success = false;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET STAFF
    getStaffSuccess(state, action) {
      state.isLoading = false;
      state.staff = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.success = false;
      state.user = action.payload;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      const user = action.payload;
      state.isLoading = false;
      state.success = true;
      state.users = [user, ...current(state.users)];
    },

    // CREATE USER
    createStaffSuccess(state, action) {
      const staff = action.payload;
      state.isLoading = false;
      state.success = true;
      state.staff = [staff, ...current(state.staff)];
    },

    // DELETE USER
    deleteUserSuccess(state, action) {
      const { id } = action.payload;
      const users = filter(state.users, (_user) => _user.id !== id);
      state.isLoading = false;
      state.success = true;
      state.users = users;
    },

    // UPDATE USER INSTRUCTOR
    updateUserSuccess(state, action) {
      const user = action.payload;
      const users = map(current(state.users), (_user) => {
        if (_user.id === user.id) return user;
        return _user;
      });
      state.isLoading = false;
      state.success = true;
      state.users = users;
      state.user = null;
    },

    // DELETE USER
    deleteStaffSuccess(state, action) {
      const { id } = action.payload;
      const staff = filter(state.staff, (_user) => _user.id !== id);
      state.isLoading = false;
      state.success = true;
      state.staff = staff;
    },

    // UPDATE USER INSTRUCTOR
    updateStaffSuccess(state, action) {
      const user = action.payload;
      const staff = map(current(state.staff), (_user) => {
        if (_user.id === user.id) return user;
        return _user;
      });
      state.isLoading = false;
      state.success = true;
      state.staff = staff;
      state.user = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetFlagsUser() {
  return async () => {
    dispatch(slice.actions.resetFlags());
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/panel/users`);
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStaff() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/panel/staff`);
      dispatch(slice.actions.getStaffSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/panel/user/${id}`);
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser(user: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/panel/user', {
        username: user.username,
        email: user.email,
        password: user.password,
        phoneNumber: user.phoneNumber,
        address1: user.address1,
        address2: user.address2,
        city: user.city,
        zipCode: user.zipCode,
      });
      dispatch(slice.actions.createUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createStaff(staff: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/panel/staff', {
        email: staff.email,
        password: staff.password,
        firstName: staff.firstName,
        lastName: staff.lastName,
      });
      dispatch(slice.actions.createStaffSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(id: string, user: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/panel/user/${id}`, {
        username: user.username,
        email: user.email,
        password: user.password,
        phoneNumber: user.phoneNumber,
        address1: user.address1,
        address2: user.address2,
        city: user.city,
        zipCode: user.zipCode,
      });
      dispatch(slice.actions.updateUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateStaff(id: string, staff: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/panel/staff/${id}`, {
        email: staff.email,
        password: staff.password,
        firstName: staff.firstName,
        lastName: staff.lastName,
      });
      dispatch(slice.actions.updateStaffSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/panel/user/${id}`);
      dispatch(slice.actions.deleteUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteStaff(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/panel/user/${id}`);
      dispatch(slice.actions.deleteStaffSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
