import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

// ENVIRONMENT
export const LANGS = [
  { code: 'en', label: 'English (EN)' },
  { code: 'es', label: 'Spanish (ES)' },
];

export const TYPES = [
  {
    code: 'fi-generic-demo-environment',
    label: 'FI Generic Demo Environment',
    isDefault: true,
  },
  {
    code: 'e-commerce-generic-demo-environment',
    label: 'E-commerce Generic  Demo  Environment',
    isDefault: true,
  },
  {
    code: 'financial-institution',
    label: 'Financial Institution',
    isDefault: false,
  },
  {
    code: 'e-commerce',
    label: 'E-commerce',
    isDefault: false,
  },
];

export const SECURITIES = [
  {
    code: 'NuDetect',
    label: 'NuDetect',
    isDefault: false,
  },
  {
    code: 'TransactionIdentityInsights',
    label: 'Transaction Identity Insights',
    isDefault: false,
  },
  {
    code: 'AccountIdentityInsights',
    label: 'Account Identity Insights',
    isDefault: false,
  },
  {
    code: 'BiometricAuthenticationService',
    label: 'Biometric Authentication Service',
    isDefault: false,
  },
];
