import { filter, map } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { EnvironmentState } from '../../@types/environment';

// ----------------------------------------------------------------------

const initialState: EnvironmentState = {
  isLoading: false,
  success: false,
  error: false,
  environment: null,
  environments: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // RESET
    resetFlags(state) {
      state.isLoading = false;
      state.success = false;
      state.error = false;
      state.environment = null;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.success = false;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ENVIRONMENTS
    getEnvironmentsSuccess(state, action) {
      state.isLoading = false;
      state.environments = action.payload;
    },

    // GET ENVIRONMENT
    getEnvironmentSuccess(state, action) {
      state.isLoading = false;
      state.success = false;
      state.environment = action.payload;
    },

    // CREATE ENVIRONMENT
    createEnvironmentSuccess(state, action) {
      const environment = action.payload;
      state.isLoading = false;
      state.success = true;
      state.environments = [environment, ...current(state.environments)];
    },

    // DELETE ENVIRONMENT
    deleteEnvironmentSuccess(state, action) {
      const { id } = action.payload;
      const environments = filter(state.environments, (_environment) => _environment.id !== id);
      state.isLoading = false;
      state.success = true;
      state.environments = environments;
    },

    // UPDATE ENVIRONMENT
    updateEnvironmentSuccess(state, action) {
      const environment = action.payload;
      const environments = map(current(state.environments), (_environment) => {
        if (_environment.id === environment.id) return environment;
        return _environment;
      });
      state.isLoading = false;
      state.success = true;
      state.environments = environments;
      state.environment = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetFlagsEnvironment() {
  return async () => {
    dispatch(slice.actions.resetFlags());
  };
}

// ----------------------------------------------------------------------

export function getEnvironments() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/panel/environments`);
      dispatch(slice.actions.getEnvironmentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEnvironment(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/panel/environment/${id}`);
      dispatch(slice.actions.getEnvironmentSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function createEnvironment(environment: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/panel/Environment', environment);
      dispatch(slice.actions.createEnvironmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEnvironment(id: string, environment: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/panel/environment/${id}`, environment);
      dispatch(slice.actions.updateEnvironmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEnvironment(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/panel/environment/${id}`);
      dispatch(slice.actions.deleteEnvironmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
